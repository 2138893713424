import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiEye, FiEdit, FiTrash2, FiPlus, FiMinus } from 'react-icons/fi'; // Using react-icons library
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications
import { confirmAlert } from 'react-confirm-alert'; // Import the confirmation alert
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS for the alert



const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1;
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000;
`;

const Card = styled.div`
  background-color: #f5f5f5;
  box-shadow: 0px 5px 10px #00000038;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Segoe UI', 'Semibold';
  border-radius: 27px;
  opacity: 1;
  color: #fff;
  margin-right: 30px; // Space to the right of the button



&.add-location {
  background-color: #293495;
  white-space: nowrap; /* Prevents text from wrapping */

}
    &.reset {
    background-color: red;
    border-radius: 12px;
    box-shadow: 0px 10px 20px #2b349752;
  }

  &.submit {
    background-color: #3d46b4;
    border-radius: 12px;
    box-shadow: 0px 10px 20px #2b349752;
  }

  & > svg {
    margin-left: 8px;
  }`
  ;

const LocationInput = styled.input`
  border: none;
  border-bottom: 2px solid #3d46b4;
  outline: none;
  font-size: 16px;
  padding: 10px;
  margin-left: 20px;
  width: 200px;
  background: transparent;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;
const IconWrapper = styled.span`
  cursor: pointer;
  color: #2B3497;
  margin: 0 5px;
`;
const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', 'Semibold';
  color: #2B3497;
  margin-bottom: 5px;
  font-weight: 600;
`;

const Asterisk = styled.span`
  color: red;
`;
const FormItem = styled.div`
  flex: 1 1 300px; /* Flex-grow, flex-shrink, and minimum width */
`;
const Dropdown = styled.select`
  border: none;
  border-bottom: 2px solid #3d46b4;
  padding: 10px;
  background: transparent;
  font-family: 'Segoe UI', Regular;
  outline: none; /* Remove default focus outline */
  margin-right: 20px; // Space to the right of the dropdown
  flex: 1; // Makes the dropdown take up more space
`;
const Input = styled.input`
  border: none;
  border-bottom: 2px solid #3d46b4;
  background: transparent;
  padding: 10px;
  font-size: 16px;
  outline: none;
  flex: 1;
    margin-right: 20px; // Space to the right of the dropdown

`;


const AddGatePage = () => {
  const [showInput, setShowInput] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [locations, setLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [gates, setGates] = useState([]);

  const rowsPerPage = 7;
  const [formValues, setFormValues] = useState({
    gate: '',
    location: '',
  });

  useEffect(() => {
    const fetchGates = async () => {
      try {
        const response = await axios.get('/Location/GetAllGates');
        if (response.data) {
          // Assuming the API returns an array of locations
          setGates(response.data);
          setShowPagination(true); // Show pagination after submitting at least one row

        } else {
          setGates([]); // Ensure locations is always an array
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
        setGates([]); // Ensure locations is always an array even if the request fails
      }
    };

    fetchGates();
  }, []);



  const handleAddLocationClick = () => {
    setShowInput(!showInput);
  };

  const handleLocationSubmit = async () => {
    if (formValues.gate && formValues.location) {
      try {
          // Prepare the request body
          const requestBody = {
            name: formValues.gate, // the input locationName
            locationID: formValues.location, // or set this dynamically if required
            isActive: true, // or set this based on your form logic
            createdOn: new Date().toISOString(), // current date and time
            modifiedOn: new Date().toISOString() // current date and time
          };
    
          // Make the POST request
          const response = await axios.post('/Location/InsertGate', requestBody);
          
          if (response.status === 200) {
            // Show success toast message
            toast.success('Gate added successfully');
    
            // Fetch the updated list of locations
            const updatedLocationsResponse = await axios.get('/Location/GetAllGates');
            setGates(updatedLocationsResponse.data);
            
            // Clear the input field
            //setLocationName('');
            setShowInput(false); // Close the input field
          } else {
            // Handle unexpected responses
            toast.error('Unexpected response from the server');
          }
        } catch (error) {
          console.error('Error adding Gate:', error);
          toast.error('Error adding Gate');
        }
      } else {
        toast.error('Gate name cannot be empty');
      }
    };

    const handleDelete = async (id, locationId, gateName) => {
      try {
        // Prepare the request body
        const requestBody = {
          id: id, // Pass the actual gate ID
          name: gateName, // Pass the gate name dynamically
          locationID: locationId, // Pass the actual location ID
          isActive: false, // Setting to false to "delete"
          createdOn: new Date().toISOString(), // Current date and time
          modifiedOn: new Date().toISOString() // Current date and time
        };
    
        // Make the PUT request
        const response = await axios.put('/Location/UpdateGate', requestBody);
    
        if (response.status === 200) {
          // Update local state to remove the deleted gate from the UI
          setGates((prevData) => prevData.filter(gate => gate.id !== id));
          toast.success('Gate Deleted Successfully');
        }
      } catch (error) {
        console.error('Error deleting Gate:', error);
        toast.error('Failed to Delete Gate');
      }
    };
              
  const handleReset = () => {
    setSearchTerm('');
    setLocationName('');
    setCurrentPage(1); // Reset pagination to the first page

  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredLocations = gates.filter(gate => {
    const name = gate.name || ''; // Ensure a default value if locationName is null or undefined
    return name.toLowerCase().includes(searchTerm?.toLowerCase() ?? '');
  });
  

  // Pagination logic
  const itemsPerPage = 7;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLocations.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredLocations.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredLocations.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const locationNameResponse = await axios.get('/location');
        setLocations(locationNameResponse.data);



      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const confirmEdit = (id, locationId, gateName) => {
    confirmAlert({
      title: 'Confirm Edit',
      message: `Are you sure you want to edit ${gateName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleEdit(id, locationId, gateName)
        },
        {
          label: 'No',
          onClick: () => toast.info('Edit cancelled')
        }
      ]
    });
  };
  
  const handleEdit = async (id, locationId, gateName) => {
    try {
      // Prepare the request body
      const requestBody = {
        id: id, // Pass the actual gate ID
        name: gateName, // Pass the gate name dynamically
        locationID: locationId, // Pass the actual location ID
        isActive: true, // Setting to true to "activate/edit"
        createdOn: new Date().toISOString(), // Current date and time
        modifiedOn: new Date().toISOString() // Current date and time
      };
  
      // Make the PUT request
      const response = await axios.put('/Location/UpdateGate', requestBody);
  
      if (response.status === 200) {
        // Optionally, you can update the gates list or trigger a re-render if necessary
        toast.success('Gate Updated Successfully');
      }
    } catch (error) {
      console.error('Error updating Gate:', error);
      toast.error('Failed to Update Gate');
    }
  };
    
  return (
    <Container>
      <Header>
        <Heading>Add Gate</Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </SearchBox>
      </Header>
      <Card>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button className="add-location" onClick={handleAddLocationClick}>
            Add Gate {showInput ? <FiMinus size={20} /> : <FiPlus size={20} />}
          </Button>
          {showInput && (
            <>
            <FormItem>
                          <Label>Gate Name</Label>
            <Input
              name="gate" // Added the name attribute here
              type="text"
              placeholder="Enter Gate Name"
              value={formValues.gate}
              onChange={handleInputChange}
              />
            
</FormItem>

              <FormItem>
                <Label>
                  Select Location<Asterisk>*</Asterisk>
                </Label>
                <Dropdown
                  name="location"
                  value={formValues.location}
                  onChange={handleInputChange}
                  style={{ fontSize: "12px" }}

                >
                  <option value="">Select Location</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.locationName}
                    </option>
                  ))}
                </Dropdown>
              </FormItem></>

          )}
        </div>
        <ButtonGroup>
          <Button className="reset" onClick={handleReset}>
            Reset
          </Button>
          <Button className="submit" onClick={handleLocationSubmit}>
            Submit
          </Button>
        </ButtonGroup>
      </Card>
      <TableContainer>
        <StyledTable>
          <thead>
            <tr>
              <TableHeader>Gate Name</TableHeader>
              <TableHeader>Action</TableHeader>

            </tr>
          </thead>
          <tbody>
            {currentRows.map((gate, index) => (
              <TableRow key={index}>
                <TableCell>{gate.name}</TableCell>
                <TableCell>
                <IconWrapper onClick={() => confirmEdit(gate.id, gate.locationID, gate.name)}>
    <FiEdit />
                    </IconWrapper>
                    <IconWrapper onClick={() => handleDelete(gate.id, gate.locationID, gate.name)}>
  <FiTrash2 />
</IconWrapper>
                  </TableCell>
              </TableRow>
            ))}
          </tbody>
        </StyledTable>
        <PaginationContainer showPagination={filteredLocations.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>
      </TableContainer>
      <ToastContainer /> 

    </Container>
  );
};

export default AddGatePage;
