import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px; /* Adjusted margin-bottom for spacing */
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ButtonGroupLeft = styled.div`
  display: flex;
  gap: 10px; /* Space between buttons */
`;

const ButtonGroupRight = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Space between buttons */
`;

const Button = styled.button`
  background-color: #2B3497;
  color: #FFF;
  box-shadow: 0px 5px 10px #2B349754;
  border-radius: 12px;
  opacity: 1;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #1E2A6D;
  }
  &:active {
    background-color: #162055;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 6px;
  opacity: 1;
`;

const TableHeader = styled.thead`
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  color: #000;
`;

const TableRow = styled.tr``;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
`;

const GroupMemberDetailsUploadPage = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const location = useLocation();
    const { formData } = location.state || {};
    const { memberDetails } = location.state || {}; // Get memberDetails from location.state
    const { checkType } = location.state || {};
    const { formDataAdditional } = location.state || {};


    const fileInputRef = useRef(null);
    const [tableData, setTableData] = useState(memberDetails || []); // Initialize tableData with memberDetails
    const [file, setFile] = useState(null); // Store the chosen file
    const [fileUploaded, setFileUploaded] = useState(false); // Track if the file has been uploaded

    const handleChooseFileClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const chosenFile = event.target.files[0];
        if (chosenFile) {
            setFile(chosenFile); // Save the chosen file
            setFileUploaded(false); // Reset file upload status
        }
    };

    const handleUploadFileClick = () => {
        if (file) {
            readExcelFile(file);
            setFileUploaded(true); // Set file upload status to true
        } else {
            alert('Please choose a file first');
        }
    };

    const readExcelFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            setTableData(jsonData);
        };
        reader.readAsArrayBuffer(file);
    };

    const handleSampleTemplateClick = () => {
        const sampleData = [
            {
                "groupMemberName": "",
                "visitorMobile": "",
                "idProofType": "",
                "idProofNo": ""
            }
        ];
        const worksheet = XLSX.utils.json_to_sheet(sampleData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SampleTemplate");

        // Generate Excel file and download it
        XLSX.writeFile(workbook, "SampleTemplate.xlsx");
    };

    useEffect(() => {
        console.log("formData", formData);
        console.log("memberDetails", memberDetails);
        console.log("checkType", checkType);
        const getData = async () => {
            try {
                const getLocation = await axios.get(`/Location/Location/${formData.meetingVenue}`);
                console.log("getLocation", getLocation);
                const empID = localStorage.getItem('employeeId');
                const appointmentUpdateData =
                {
                    AppointmentStatus: "Checked-In",
                    ModifiedBy: empID,
                    Id: memberDetails[0].groupAppointmentId,
                    ModifiedDate: new Date().toISOString(),
                    VisitorMobile: formData.visitorMobile
                }

                const UpdateAppointment = await axios.put('/GroupAppointment', appointmentUpdateData);
                console.log("UpdateAppointment", UpdateAppointment);
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
        getData();
    }, []);

    const handleSubmitClick = async () => {
        console.log("formData", formData);
        console.log('Table Data:', tableData);
        const empID = localStorage.getItem('employeeId');
        const getManagerDetails = await axios.get(`/Employee/${empID}`);
        console.log("getManagerDetails", getManagerDetails);
        const getLocation = await axios.get(`/Location/Location/${formData.meetingVenue}`);
        console.log("getLocation", getLocation);
        const addformData = formDataAdditional


        const requestData = [{
            checkInId: 0,
            hostName: String(getManagerDetails.data.id),
            hostMobile: getManagerDetails.data.employeeMobile,
            hostEmail: getManagerDetails.data.employeeMailId,
            checkInDateTime: new Date().toISOString(),
            visitorName: formData.groupLeaderName,
            visitorMobile: formData.visitorMobile,
            visitorCompany: formData.company,
            selectedVisitorTypeId: formData.selectedVisitorTypeId,
            locationId: getLocation.data.id,
            departmentId: 0,
            idProofId: String(formData.selectedIdProofTypeId),
            visitorIdNumber: null,
            visitorAssets: "",
            visitorPhoto: "",
            visitorSignature: "",
            duration: "",
            passReturned: "",
            passReturnedDateTime: formData.meetingTo,
            passNo: "string",
            groupAppointmentId: memberDetails[0].groupAppointmentId,
            checkinType: checkType,
            status: "",
            checkOutType: null,
            temperature: 0,
            txtManagersID: "",
            txtFloor: "",
            txtMeetingRoom: "",
            isCoupon: 0,
            assitanceRequired: addformData.assistanceRequired,
            serviceProviderAccess: addformData.serviceProviderAccess,
            wifiRequired: addformData.wifiRequired,
            escort: addformData.escortRequired,
            additionalNotification: addformData.additionalNotification,
            messageToVisitor: addformData.additionalMessage,
            checkinInstruction: addformData.checkInInstructions,
            allDay: addformData.isAllDay,
            repeatVisit: addformData.repeatVisit,
            recurrancePattern: addformData.recurrencePattern,
            recurEvery: parseInt(addformData.recurEvery, 10),
            selectedRecurrence: addformData.selectedRecurrence,
            country: "India"
        }]

        console.log("Request Data:", requestData);
        try {
            const response = await axios.post('/GroupCheckIn', requestData);
            console.log("response", response);
            const updatedFormData = {
                VisitorName: formData.visitorName,
                MailSubject: 'Visitor CheckIn Notification',
                MailType: 'CHKINVISITOR',
                MeetingOn: formData.meetingFrom,
                VisitorMobile: formData.visitorMobile,
                MeetingVenue: formData.meetingVenue,
                txtManagersID: null,
                ManagerMail: getManagerDetails.data.managerMailID,
                SecsEmailId: null,
                VisitorCompany: formData.company,
                HostName: getManagerDetails.data.employeeName,
                ToAddress: formData.visitorEmail,
            }
            console.log("updatedFormData", updatedFormData);
            const updateMail = await axios.post('/Mail', updatedFormData);
            console.log("updateMail", updateMail);
            const sendSmsData = {
                AppointmentDtTm: formData.meetingFrom,
                AppointmentVenue: formData.meetingVenue || '',
                ContactNo: "+91" + getManagerDetails.data.employeeMobile,
                HostName: getManagerDetails.data.employeeName,
                SMSType: "MsgToHostCHKIN",
                VisitorName: formData.visitorName,
                txtManagersID: null
            }
            console.log("sendSmsData", sendSmsData);
            const updateSendSms = await axios.post('/SendSMS', sendSmsData);
            console.log("updateSendSms", updateSendSms);

            if (response.status === 200) {
                navigate('/layout/GroupCheckIn/MenuItem');
                // Handle the response data, e.g., display details or update state
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <Container>
            <Header>
                <Heading>Group Member Details</Heading>
            </Header>
            <ButtonsContainer>
                <ButtonGroupLeft>
                    <Button onClick={handleChooseFileClick}>Choose File</Button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".xlsx,.xls"
                        onChange={handleFileChange}
                    />
                    <Button onClick={handleUploadFileClick} disabled={!file}>Upload File</Button>
                </ButtonGroupLeft>
                <ButtonGroupRight>
                    <Button onClick={handleSampleTemplateClick}>Sample Template</Button>
                    <Button onClick={handleSubmitClick}>Submit</Button>
                </ButtonGroupRight>
            </ButtonsContainer>

            <TableContainer>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Group Member Name</TableHeaderCell>
                            <TableHeaderCell>Mobile Number</TableHeaderCell>
                            <TableHeaderCell>ID Proof Type</TableHeaderCell>
                            <TableHeaderCell>ID Proof No</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {tableData.length > 0 ? (
                            tableData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.groupMemberName}</TableCell>
                                    <TableCell>{row.visitorMobile}</TableCell>
                                    <TableCell>{row.idProofType}</TableCell>
                                    <TableCell>{row.idProofNo}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan="4">No data available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default GroupMemberDetailsUploadPage;
