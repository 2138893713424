import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchVisitorTypes, fetchIdProofTypes, fetchMeetingVenues, insertAppointment, fetchGateDetails, fetchBuildingsByGate } from '../../services/Api';
import Webcam from 'react-webcam'; // Import react-webcam
import { User } from 'react-feather'; // Import the User icon
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 18px 7px 33px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Heading = styled.h1`
  font-size: 25px;
  font-family: 'Segoe UI', Semibold;
  color: #060000;
  margin: 0;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  width: 81px;
  height: 32px;
  border-radius: 7px;
  box-shadow: 0px 5px 10px #2b349754;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-family: 'Segoe UI', Semibold;
`;

const ResetButton = styled(Button)`
  background-color: red;
`;

const SubmitButton = styled(Button)`
  background-color: #2b3497;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  flex: 1;
  background: radial-gradient(circle, #ffffff, #e3e3e3);
  box-shadow: 0px 10px 20px #00000038;
  border-radius: 15px;
  padding: 27px;
  opacity: 1;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', Semibold;
  color: #000000;
  margin-bottom: 5px;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 335px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px 0;
    border: none;
    font-family: 'Segoe UI', Regular;
    background: transparent;
    border-bottom: 1px solid #2b3497;
    &:focus {
      outline: none;
      border-bottom: 2px solid #2b3497;
    }
  }
     @media (max-width: 1920px) and (min-width: 1720px) {
       width: 545px;

  }
       @media (max-width: 1720px) and (min-width: 1420px) {
       width: 391px;

  }
         @media (max-width: 1420px) and (min-width: 1220px) {
       width: 260px;

  }
              @media (max-width: 1220px) and (min-width: 1020px) {
       width: 200px;

  }
`;

const ProfileCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  width: 131px;
  height: 131px;
  background: linear-gradient(#ffffff, #f6f6f6);
  border-radius: 50%;
  box-shadow: 0px 10px 10px #00000024;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const ProfileIcon = styled.div`
  width: 134px;
  height: 131px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2b3497;
  font-size: 30px;
  font-weight: bold;
  position: relative;
`;

const PlusIcon = styled.div`
  width: 47px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BFBFBF;
  font-size: 44px;
  font-weight: 700;
`;

const ProfileText = styled.div`
  font-family: 'Segoe UI', Semibold;
  font-size: 18px;
  color: #ff3030;
  margin-bottom: 20px;
`;

const PhotoButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: #343d9b;
  box-shadow: 0px 10px 10px #2b349733;
  border: 2px solid #343d9b;
  border-radius: 8px;
  opacity: 1;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: transparent;
  border: 2px solid #343d9b;
  border-radius: 8px;
  box-shadow: 0px 10px 10px #2b349733;
  opacity: 1;
  cursor: pointer;
  margin-bottom: 20px;
           @media (max-width: 1420px) and (min-width: 1220px) {
  width: 125px;

  }
              @media (max-width: 1220px) and (min-width: 1020px) {
  width: 100px;

  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-family: 'Segoe UI', Semibold;
  font-size: 14px;
`;
const Checkbox = styled.input`
  margin-right: 8px;
`;

const PrivacyLink = styled.a`
  color: #2b3497;
  margin-left: 5px;
  text-decoration: none;
`;
const WebcamContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
`;
const ButtonRow = styled.div`
  display: flex;
  gap: 10px; /* Adjust gap between buttons */
  margin-bottom: 20px; /* Optional: Adjust margin bottom */
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background: none;
  border: none;
  font-family: 'Segoe UI', Semibold;
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  color: #000;
  &:hover {
    color: #2B3497;
  }
  ${({ active }) => active && `
    border-bottom: 2px solid #2B3497;
    color: #2B3497;
  `}
`;

const TabContent = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
`;
const HeaderN = styled.h3`
  margin-top: 16px;
  color: ${(props) => (props.disabled ? '#a9a9a9' : '#000')}; /* Change color if disabled */
`;

const DropdownContainer = styled.div`
  margin-top: 16px;
`;

const DropdownLabel = styled.label`
  font-weight: 500;
  margin-right: 8px;
`;

const StyledDropdown = styled.select`
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #293495;
    outline: none;
  }

  option {
    padding: 8px;
  }
`;

const InputContainer = styled.div`
  margin-top: 16px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  gap:14px
`;

const SpinnerInput = styled.input`
  padding: 10px;
  width: 60px;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #293495;
    outline: none;
  }
`;

const SpinnerInputLabel = styled.label`
  font-weight: 500;
`;


const RecurrenceInput = styled.input`
  padding: 10px;
  width: 80px;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  margin-left: 8px;

  &:focus {
    border-color: #293495;
    outline: none;
  }
`;

const AdditionalInputContainer = styled.div`
  margin-top: 16px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
`;
const SelectedValuesContainer = styled.div`
  margin-top: 10px;
  padding: 12px;
  background-color: #f9f9f9;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
`;

const SelectedHeader = styled.h4`
  margin-bottom: 11px;
  font-weight: bold;
  color: #293495;
`;


const ValueRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SelectedValue = styled.div`
  font-weight: bold;
  color: #000;
`;


const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SwitchRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SwitchLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
`;

const Switch = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SwitchText = styled.span`
  font-weight: bold;
  margin-left: 10px;
  color: ${({ isOn }) => (isOn ? '#28a745' : '#6c757d')}; // Green for Yes, Gray for No
`;

const SwitchSlider = styled.div`
  width: 50px;
  height: 24px;
  background-color: ${({ isOn }) => (isOn ? '#28a745' : '#ccc')}; // Green for Yes, Gray for No
  border-radius: 34px;
  position: relative;
  transition: 0.4s;

  &:before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    left: ${({ isOn }) => (isOn ? '26px' : '4px')}; // Moves the slider based on the toggle state
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
`;

const InputFieldContainer = styled.div`
  margin-top: 20px;
`;

const InputLabel = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  resize: none;
  &:focus {
    border-color: #28a745; // Green border on focus
  }
`;

const GroupAppoinmentFormPage = () => {
    const location = useLocation();
    const { checkType } = location.state || {};
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [selectedGate, setSelectedGate] = useState('');
    const [selectedVisitorType, setSelectedVisitorType] = useState('');
    const [selectedIdProofType, setSelectedIdProofType] = useState('');

    const [visitorTypes, setVisitorTypes] = useState([]);
    const [idProofTypes, setIdProofTypes] = useState([]);

    const [meetingvenues, setMeetingvenues] = useState([]);
    const [gates, setGates] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [activeTab, setActiveTab] = useState('details'); // State for tab management

    const [isChecked, setIsChecked] = useState(false);
    const [recurrencePattern, setRecurrencePattern] = useState('');
    const [selectedrecurrencePattern, setSelectedrecurrencePattern] = useState('');
    const [recurrencePatternAPI, setRecurrencePatternAPI] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedWeekday, setSelectedWeekday] = useState('');
    const [selectedWeekdayAPI, setSelectedWeekdayAPI] = useState('');

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedMonthAPI, setSelectedMonthAPI] = useState('');


    const [isAllDayChecked, setIsAllDayChecked] = useState(false);
    const [recurEvery, setRecurEvery] = useState(1);


    const [isAssistanceRequired, setIsAssistanceRequired] = useState(false);
    const [isServiceProviderAccess, setIsServiceProviderAccess] = useState(false);
    const [isWiFiRequired, setIsWiFiRequired] = useState(false);
    const [isEscortRequired, setIsEscortRequired] = useState(false);
    const [isadditionalNotify, setIsAdditionalNotify] = useState(false);

    const [additionalMessage, setAdditionalMessage] = useState('');
    const [checkInInstructions, setCheckInInstructions] = useState('');

    const toggleAssistanceRequired = () => setIsAssistanceRequired(!isAssistanceRequired);
    const toggleServiceProviderAccess = () => setIsServiceProviderAccess(!isServiceProviderAccess);
    const toggleWiFiRequired = () => setIsWiFiRequired(!isWiFiRequired);
    const toggleEscortRequired = () => setIsEscortRequired(!isEscortRequired);
    const toggleadditionalNotifyRequired = () => setIsAdditionalNotify(!isadditionalNotify);

    const handleAdditionalMessageChange = (e) => setAdditionalMessage(e.target.value);
    const handleCheckInInstructionsChange = (e) => setCheckInInstructions(e.target.value);



    const handleCheckboxChange = () => {
        setIsChecked((prev) => !prev);
    };
    const handleDropdownChange = (event) => {
        console.log("event.target", event.target);
        const selectedText = event.target.options[event.target.selectedIndex].text;
        console.log("selectedText", selectedText);
        // This gets the text of the selected option

        setRecurrencePattern(event.target.value);
        if (event.target.value == 'Daily') {
            setRecurrencePatternAPI(1);
        }
        if (event.target.value == 'Weekly') {
            setRecurrencePatternAPI(2);
        }
        if (event.target.value == 'Monthly') {
            setRecurrencePatternAPI(3);
        }

    };
    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
    };
    const handleWeekdayChange = (event) => {
        setSelectedWeekday(event.target.value);
        if (event.target.value == 'Sunday') {
            setSelectedWeekdayAPI(1);
        }
        if (event.target.value == 'Monday') {
            setSelectedWeekdayAPI(2);
        }
        if (event.target.value == 'Tuesday') {
            setSelectedWeekdayAPI(3);
        }
        if (event.target.value == 'Wednesday') {
            setSelectedWeekdayAPI(4);
        }
        if (event.target.value == 'Thursday') {
            setSelectedWeekdayAPI(5);
        }
        if (event.target.value == 'Friday') {
            setSelectedWeekdayAPI(6);
        }
        if (event.target.value == 'Saturday') {
            setSelectedWeekdayAPI(7);
        }
    };
    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        if (event.target.value == 'January') {
            setSelectedMonthAPI(1);
        }
        if (event.target.value == 'February') {
            setSelectedMonthAPI(2);
        }
        if (event.target.value == 'March') {
            setSelectedMonthAPI(3);
        }
        if (event.target.value == 'April') {
            setSelectedMonthAPI(4);
        }
        if (event.target.value == 'May') {
            setSelectedMonthAPI(5);
        }
        if (event.target.value == 'June') {
            setSelectedMonthAPI(6);
        }
        if (event.target.value == 'July') {
            setSelectedMonthAPI(7);
        }
        if (event.target.value == 'August') {
            setSelectedMonthAPI(8);
        }
        if (event.target.value == 'September') {
            setSelectedMonthAPI(9);
        }
        if (event.target.value == 'October') {
            setSelectedMonthAPI(10);
        }
        if (event.target.value == 'November') {
            setSelectedMonthAPI(11);
        }
        if (event.target.value == 'December') {
            setSelectedMonthAPI(12);
        }
    };
    const handleAllDayCheckboxChange = () => {
        setIsAllDayChecked((prev) => !prev);
    };

    const handleRecurEveryChange = (event) => {
        setRecurEvery(event.target.value);
    };

    const getRecurrencePatternAPIValue = () => {
        switch (recurrencePattern) {
            case 'Daily':
                return 1;
            case 'Weekly':
                return 2;
            case 'Monthly':
                return 3;
            default:
                return null; // Handle default case as needed
        }
    };


    // Generate an array for days 1 to 31
    const days = Array.from({ length: 31 }, (_, i) => i + 1);



    useEffect(() => {
        const getVisitorTypes = async () => {
            try {
                const types = await fetchVisitorTypes();
                setVisitorTypes(types);
            } catch (error) {
                console.error('Error fetching visitor types:', error);
            }
        };
        const getIdProofTypes = async () => {
            try {
                const types = await fetchIdProofTypes();
                setIdProofTypes(types);
            } catch (error) {
                console.error('Error fetching visitor types:', error);
            }
        };
        const getMeetingvenues = async () => {
            try {
                const venues = await fetchMeetingVenues();
                setMeetingvenues(venues);
            } catch (error) {
                console.error('Error fetching meeting venues:', error);
            }
        };
        const getGateDetails = async () => {
            try {
                const venues = await fetchGateDetails();
                setGates(venues);
            } catch (error) {
                console.error('Error fetching Gate venues:', error);
            }
        };

        getVisitorTypes();
        getIdProofTypes();
        getMeetingvenues();
        getGateDetails();
    }, []);
    const { data } = location.state || {}; // Destructure
    console.log("data", data);

    useEffect(() => {
        if (data) {
            setFormData({
                groupLeaderName: data?.groupLeaderName || '',
                visitorEmail: data?.visitorEmail || '',
                visitorMobile: data?.visitorMobile || '',
                meetingVenue: data?.meetingVenue || '',
                company: data?.visitorCompany || '',
                meetingFrom: data?.appointmentDate || '',
                meetingTo: data?.validTill || '',
                selectedVisitorTypeId: data?.selectedVisitorTypeId || '',
                selectedIdProofTypeId: data?.idProofType || '',
                idProofNo: data?.idProofNo || '',
                gate: data?.gate || '',
                building: data?.building || '',
            })
        }

        setIsChecked(data.repeatVisit);
        setRecurrencePattern(data.recurrancePattern === 1 ? "Daily" : data.recurrancePattern === 2 ? "Weekly" : "Monthly"); // Set to default value (1 for Daily)
        setSelectedDay('');
        setSelectedWeekday('');
        setSelectedMonth('');
        setRecurEvery(data.recurEvery);
        setIsAllDayChecked(data.allDay);
        setIsAssistanceRequired(data.assitanceRequired);
        setIsServiceProviderAccess(data.serviceProviderAccess);
        setIsWiFiRequired(data.wifiRequired);
        setIsEscortRequired(data.escort === "yes");
        setAdditionalMessage(data.messageToVisitor);
        setCheckInInstructions(data.checkinInstruction);

    }, [data])


    useEffect(() => {
        if (selectedGate) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                gate: selectedGate.name, // Use the gate name in the form data
            }));
        }
    }, [selectedGate]);

    const handleGateChange = async (event) => {
        const gateID = parseInt(event.target.value, 10); // Convert to integer if gate.id is a number
        const selectedGateObject = gates.find(gate => gate.id === gateID);

        setSelectedGate(selectedGateObject); // Store the entire gate object


        try {
            const buildings = await fetchBuildingsByGate(gateID);
            setBuildings(buildings);
        } catch (error) {
            console.error('Error fetching buildings:', error);
        }
    };



    const [formData, setFormData] = useState({
        groupLeaderName: '',
        visitorEmail: '',
        visitorMobile: '',
        company: '',
        meetingFrom: null,
        meetingTo: null,
        selectedVisitorTypeId: selectedVisitorType,
        selectedIdProofTypeId: selectedIdProofType,
        idProofNo: '',
        gate: '',
        building: '',
    });

    const [formErrors, setFormErrors] = useState({
        groupLeaderName: false,
        visitorEmail: false,
        visitorMobile: false,
    });


    const handleReset = () => {
        setFormData({
            groupLeaderName: '',
            visitorEmail: '',
            visitorMobile: '',
            meetingVenue: '',
            company: '',
            meetingFrom: null,
            meetingTo: null,
            selectedVisitorTypeId: '',
            selectedIdProofTypeId: '',
            idProofNo: '',
            gate: '',
            building: '',
        });
        setFormErrors({
            groupLeaderName: false,
            visitorEmail: false,
            visitorMobile: false,
        });
        console.log('Form reset');
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear error when user starts typing
        if (formErrors[name]) {
            setFormErrors({
                ...formErrors,
                [name]: false,
            });
        }
    };

    const handleSubmit = () => {
        let valid = true;
        const errors = {};

        if (formData.groupLeaderName === '') {
            errors.groupLeaderName = true;
            valid = false;
        }
        if (formData.visitorEmail === '') {
            errors.visitorEmail = true;
            valid = false;
        }
        if (formData.visitorMobile === '') {
            errors.visitorMobile = true;
            valid = false;
        }

        if (formData.visitorEmail !== '') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.visitorEmail)) {
                errors.visitorEmail = true;
                valid = false;
            }
        }

        if (formData.visitorMobile !== '') {
            const mobilePattern = /^\d{10}$/;
            if (!mobilePattern.test(formData.visitorMobile)) {
                errors.visitorMobile = true;
                valid = false;
            }
        }

        if (!valid) {
            setFormErrors(errors);
            console.log('Form has errors:', errors);
        } else {
            console.log('Form data:', formData);
            const fetchHostDetails = async () => {
                try {

                    let recurrencePatternAPI = getRecurrencePatternAPIValue();

                    let newSelectedRecurrencePattern = '';

                    // Determine the selected recurrence pattern based on the recurrencePatternAPI value
                    if (recurrencePatternAPI === 1) {
                        newSelectedRecurrencePattern = selectedDay;
                    } else if (recurrencePatternAPI === 2) {
                        newSelectedRecurrencePattern = selectedWeekdayAPI;
                    } else if (recurrencePatternAPI === 3) {
                        newSelectedRecurrencePattern = selectedMonthAPI;
                    }

                    // Log the new selected recurrence pattern before setting the state
                    console.log("Selected Recurrence Pattern before update:", newSelectedRecurrencePattern);

                    const formDataAdditional = {
                        repeatVisit: isChecked,
                        recurrencePattern: recurrencePatternAPI,
                        selectedRecurrence: String(newSelectedRecurrencePattern),
                        selectedDay: selectedDay,
                        selectedWeekday: selectedWeekdayAPI,
                        selectedMonth: selectedMonthAPI,
                        recurEvery: recurEvery,
                        isAllDay: isAllDayChecked,
                        assistanceRequired: isAssistanceRequired,
                        serviceProviderAccess: isServiceProviderAccess,
                        wifiRequired: isWiFiRequired,
                        escortRequired: isEscortRequired ? 'yes' : 'no',
                        additionalNotification: isadditionalNotify ? 'yes' : 'no',
                        additionalMessage: additionalMessage,
                        checkInInstructions: checkInInstructions,
                    };

                    // Log the object to the console
                    console.log('Form Data formDataAdditional:', formDataAdditional);

                    const responseGroupCheckin = await axios.get(`/GroupMember/${data.id}`);
                    console.log("responseGroupCheckin", responseGroupCheckin);
                    const memberDetails = responseGroupCheckin.data
                    navigate('/layout/group-member-page', { state: { formData, memberDetails, checkType, formDataAdditional } });

                } catch (error) {
                    console.error('Error fetching appointment details:', error);
                    // Handle error scenarios
                }
            };
            fetchHostDetails();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const webcamRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Take photo');

    const handleCapture = () => {
        if (!isCameraOn) {
            setIsCameraOn(true);
            setButtonLabel('Capture');
        } else {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log('Captured Image:', imageSrc);
            setIsCameraOn(false);
            setButtonLabel('Take photo');
        }
    };


    return (
        <Container>
            <Header>
                <Heading>Group Appointment</Heading>
                <ButtonGroup>
                    <ResetButton>Reset</ResetButton>
                    <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                </ButtonGroup>
            </Header>
            <Tabs>
                <Tab active={activeTab === 'details'} onClick={() => setActiveTab('details')}>
                    Details
                </Tab>
                <Tab active={activeTab === 'additional'} onClick={() => setActiveTab('additional')}>
                    Additional
                </Tab>
            </Tabs>
            <TabContent active={activeTab === 'details'}>
                <CardContainer>
                    <Card>
                        <FormGroup>
                            <Label>
                                Group Leader Name<Asterisk> *</Asterisk>
                            </Label>
                            <Input
                                type="text"
                                name="groupLeaderName"
                                value={formData.groupLeaderName}
                                onChange={handleInputChange}
                                placeholder="Enter Group Leader Name"
                                style={{
                                    borderBottomColor: formErrors.groupLeaderName ? 'red' : '#2b3497',
                                }}
                                disabled={formData.groupLeaderName ? true : false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Visitor Email<Asterisk> *</Asterisk>
                            </Label>
                            <Input
                                type="email"
                                name="visitorEmail"
                                value={formData.visitorEmail}
                                placeholder="Enter Email"
                                onChange={handleInputChange}
                                style={{
                                    borderBottomColor: formErrors.visitorEmail ? 'red' : '#2b3497',
                                }}
                                disabled={formData.visitorEmail ? true : false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Visitor Mobile<Asterisk> *</Asterisk>
                            </Label>
                            <Input
                                type="tel"
                                name="visitorMobile"
                                value={formData.visitorMobile}
                                placeholder="Enter 10 Digit Mobile number"
                                maxLength="10"

                                onChange={handleInputChange}
                                style={{
                                    borderBottomColor: formErrors.visitorMobile ? 'red' : '#2b3497',
                                }}
                                disabled={formData.visitorMobile ? true : false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Meeting Venue
                                <Asterisk> *</Asterisk>
                            </Label>
                            <Select
                                name="meetingvenue"
                                value={formData.meetingVenue}
                                onChange={handleChange}
                                disabled={formData.meetingVenue ? true : false}
                            >
                                <option value="">Select Meeting Venue</option>
                                {meetingvenues.map((type) => (
                                    <option key={type.locationName} value={type.locationName}>
                                        {type.locationName}
                                    </option>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Visitor Company<Asterisk> *</Asterisk>
                            </Label>
                            <Input
                                type="text"
                                name="company"
                                placeholder="Enter Company"
                                value={formData.company}
                                onChange={handleInputChange}
                                disabled={formData.company ? true : false}

                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Visitor Type
                                <Asterisk> *</Asterisk>
                            </Label>
                            <Select
                                name="selectedVisitorTypeId"
                                value={formData.selectedVisitorTypeId}
                                onChange={handleChange}
                                disabled={formData.selectedVisitorTypeId ? true : false}
                            >
                                <option value="">Select type</option>
                                {visitorTypes.map((type) => (
                                    <option key={type.id} value={type.id}>{type.visitorTypeDesc}</option>
                                ))}
                            </Select>
                        </FormGroup>
                    </Card>
                    <Card>
                        <FormGroup>
                            <Label>
                                Meeting From<Asterisk> *</Asterisk>
                            </Label>
                            <StyledDatePicker
                                selected={formData.meetingFrom}
                                onChange={(date) =>
                                    setFormData((prevData) => ({ ...prevData, meetingFrom: date }))
                                }
                                showTimeSelect
                                dateFormat="Pp"
                                disabled={formData.meetingFrom ? true : false}

                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Meeting To<Asterisk> *</Asterisk>
                            </Label>
                            <StyledDatePicker
                                selected={formData.meetingTo}
                                onChange={(date) =>
                                    setFormData((prevData) => ({ ...prevData, meetingTo: date }))
                                }
                                showTimeSelect
                                dateFormat="Pp"
                                disabled={formData.meetingTo ? true : false}
                            />
                        </FormGroup>
                        {/* <FormGroup>
                        <Label>
                            Gate
                            <Asterisk>*</Asterisk>
                        </Label>
                        <Select
                            name="gate"
                            value={selectedGate ? selectedGate.id : ''}
                            onChange={handleGateChange}
                            // disabled={formData.meetingTo ? true : false} 
                        >
                            <option value="">Select Gate</option>
                            {gates.map((gate) => (
                                <option key={gate.id} value={gate.id}>
                                    {gate.name}
                                </option>
                            ))}
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Building
                            <Asterisk>*</Asterisk>
                        </Label>
                        <Select
                            name="building"
                            value={formData.building}
                            onChange={handleChange}
                        >
                            <option value="">Select Building</option>
                            {Array.isArray(buildings) && buildings.map((building) => (
                                <option key={building.id} value={building.name}>
                                    {building.name}
                                </option>
                            ))}
                        </Select>
                    </FormGroup> */}

                        <FormGroup>
                            <Label>
                                Id Proof Type
                            </Label>
                            <Select
                                name="selectedIdProofTypeId"
                                value={formData.selectedIdProofTypeId}
                                onChange={handleChange}
                                disabled={formData.selectedIdProofTypeId ? true : false}
                            >
                                <option value="">Select type</option>
                                {idProofTypes.map((type) => (
                                    <option key={type.id} value={type.id}>{type.idProofDescription}</option>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Id Proof Number
                            </Label>
                            <Input
                                type="text"
                                name="idProofNo"
                                placeholder="Enter Id Proof Number"
                                value={formData.idProofNo}
                                onChange={handleInputChange}
                                disabled={formData.idProofNo ? true : false}
                            />
                        </FormGroup>
                    </Card>
                    <ProfileCard>
                        <ProfileText>(Only png images)</ProfileText>
                        <Circle>
                            <WebcamContainer>
                                {isCameraOn && (
                                    <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/png"
                                        width="100%"
                                        height="100%"
                                        videoConstraints={{
                                            width: 200,
                                            height: 200,
                                            facingMode: 'user',
                                        }}
                                    />
                                )}
                                {!isCameraOn && (
                                    <ProfileIcon>
                                        <User size={40} />
                                    </ProfileIcon>
                                )}
                            </WebcamContainer>
                            {/* <ProfileIcon>
                            <User size={40} />
                        </ProfileIcon>
                        <PlusIcon>+</PlusIcon> */}
                        </Circle>
                        <PhotoButton onClick={handleCapture}>{buttonLabel}</PhotoButton>
                        <ButtonRow>
                            <ActionButton>Photograph</ActionButton>
                            <ActionButton>Upload images</ActionButton>
                        </ButtonRow>

                        <CheckboxContainer>
                            <input type="checkbox" />
                            <span>
                                I agree to the
                                <PrivacyLink href="#">privacy policy</PrivacyLink>
                            </span>
                        </CheckboxContainer>
                    </ProfileCard>
                </CardContainer>
            </TabContent>
            <TabContent active={activeTab === 'additional'}>
                <CardContainer>
                    <Card>
                        <CheckboxContainer>
                            <Label>Repeat Visit</Label>
                            <Checkbox type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                        </CheckboxContainer>

                        <HeaderN disabled={!isChecked}>Scheduler</HeaderN>

                        <DropdownContainer>
                            <DropdownLabel>Recurrence Pattern:</DropdownLabel>
                            <StyledDropdown value={recurrencePattern} onChange={handleDropdownChange} disabled={!isChecked}>
                                <option value="" disabled>Select pattern</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                            </StyledDropdown>
                        </DropdownContainer>

                        {/* Daily input for selecting the day */}
                        <InputContainer show={recurrencePattern === 'Daily'}>
                            <SpinnerInputLabel>Select Day:</SpinnerInputLabel>
                            <SpinnerInput type="number" min="1" max="31" value={selectedDay} onChange={handleDayChange} />
                        </InputContainer>

                        {/* Weekly dropdown for selecting the weekday */}
                        <InputContainer show={recurrencePattern === 'Weekly'}>
                            <DropdownLabel>Select Weekday:</DropdownLabel>
                            <StyledDropdown value={selectedWeekday} onChange={handleWeekdayChange}>
                                <option value="" disabled>Select weekday</option>
                                <option value="Sunday">Sunday</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                            </StyledDropdown>
                        </InputContainer>

                        {/* Monthly dropdown for selecting the month */}
                        <InputContainer show={recurrencePattern === 'Monthly'}>
                            <DropdownLabel>Select Month:</DropdownLabel>
                            <StyledDropdown value={selectedMonth} onChange={handleMonthChange}>
                                <option value="" disabled>Select month</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </StyledDropdown>
                        </InputContainer>

                        {/* All Day checkbox and Recur Every input */}
                        <InputContainer show={recurrencePattern === 'Weekly' || recurrencePattern === 'Monthly'}>
                            <SpinnerInputLabel>Recur Every:</SpinnerInputLabel>
                            <RecurrenceInput type="number" min="1" value={recurEvery} onChange={handleRecurEveryChange} />
                        </InputContainer>

                        <AdditionalInputContainer show={recurrencePattern === 'Weekly' || recurrencePattern === 'Monthly'}>
                            <CheckboxContainer>
                                <Label>All Day</Label>
                                <Checkbox type="checkbox" checked={isAllDayChecked} onChange={handleAllDayCheckboxChange} />
                            </CheckboxContainer>
                        </AdditionalInputContainer>

                        {/* New Section for Selected Values */}
                        <SelectedValuesContainer>
                            <SelectedHeader>Selected Values:</SelectedHeader>

                            {/* Display selected Recurrence Pattern */}
                            <ValueRow>
                                <Label>Recurrence Pattern:</Label>
                                <SelectedValue>{recurrencePattern || 'Not selected'}</SelectedValue>
                            </ValueRow>

                            {/* Display selected Weekday (if Weekly) */}
                            {recurrencePattern === 'Weekly' && (
                                <ValueRow>
                                    <Label>Selected Weekday:</Label>
                                    <SelectedValue>{selectedWeekday || 'Not selected'}</SelectedValue>
                                </ValueRow>
                            )}

                            {/* Display selected Day (if Daily) */}
                            {recurrencePattern === 'Daily' && (
                                <ValueRow>
                                    <Label>Selected Day:</Label>
                                    <SelectedValue>{selectedDay || 'Not selected'}</SelectedValue>
                                </ValueRow>
                            )}

                            {/* Display selected Month (if Monthly) */}
                            {recurrencePattern === 'Monthly' && (
                                <ValueRow>
                                    <Label>Selected Month:</Label>
                                    <SelectedValue>{selectedMonth || 'Not selected'}</SelectedValue>
                                </ValueRow>
                            )}

                            {/* Display Recur Every */}
                            {(recurrencePattern === 'Weekly' || recurrencePattern === 'Monthly') && (
                                <ValueRow>
                                    <Label>Recur Every:</Label>
                                    <SelectedValue>{recurEvery}</SelectedValue>
                                </ValueRow>
                            )}
                            {(recurrencePattern === 'Weekly' || recurrencePattern === 'Monthly') && (
                                <ValueRow>
                                    <Label>All Day:</Label>
                                    <SelectedValue>{isAllDayChecked ? 'Yes' : 'No'}</SelectedValue>
                                </ValueRow>
                            )}
                        </SelectedValuesContainer>
                    </Card>
                    <Card>
                        <SwitchContainer>
                            <SwitchRow>
                                <SwitchLabel>Assistance Required</SwitchLabel>
                                <Switch isOn={isAssistanceRequired} onClick={toggleAssistanceRequired}>
                                    <SwitchSlider isOn={isAssistanceRequired} />
                                    <SwitchText isOn={isAssistanceRequired}>{isAssistanceRequired ? 'Yes' : 'No'}</SwitchText>
                                </Switch>
                            </SwitchRow>

                            <SwitchRow>
                                <SwitchLabel>Service Provider Access</SwitchLabel>
                                <Switch isOn={isServiceProviderAccess} onClick={toggleServiceProviderAccess}>
                                    <SwitchSlider isOn={isServiceProviderAccess} />
                                    <SwitchText isOn={isServiceProviderAccess}>{isServiceProviderAccess ? 'Yes' : 'No'}</SwitchText>
                                </Switch>
                            </SwitchRow>

                            <SwitchRow>
                                <SwitchLabel>WiFi Required</SwitchLabel>
                                <Switch isOn={isWiFiRequired} onClick={toggleWiFiRequired}>
                                    <SwitchSlider isOn={isWiFiRequired} />
                                    <SwitchText isOn={isWiFiRequired}>{isWiFiRequired ? 'Yes' : 'No'}</SwitchText>
                                </Switch>
                            </SwitchRow>

                            <SwitchRow>
                                <SwitchLabel>Escort Required</SwitchLabel>
                                <Switch isOn={isEscortRequired} onClick={toggleEscortRequired}>
                                    <SwitchSlider isOn={isEscortRequired} />
                                    <SwitchText isOn={isEscortRequired}>{isEscortRequired ? 'Yes' : 'No'}</SwitchText>
                                </Switch>
                            </SwitchRow>
                            <SwitchRow>
                                <SwitchLabel> Additional Notification</SwitchLabel>
                                <Switch isOn={isadditionalNotify} onClick={toggleadditionalNotifyRequired}>
                                    <SwitchSlider isOn={isadditionalNotify} />
                                    <SwitchText isOn={isadditionalNotify}>{isadditionalNotify ? 'Yes' : 'No'}</SwitchText>
                                </Switch>
                            </SwitchRow>
                        </SwitchContainer>

                        {/* Additional Input Fields */}
                        <InputFieldContainer>
                            <InputLabel>Message to Visitors:</InputLabel>
                            <StyledTextArea
                                value={additionalMessage}
                                onChange={handleAdditionalMessageChange}
                                placeholder="Enter message here"
                                rows={recurrencePattern === 'Daily' ? 3 : 6}  // Adjust as needed for the number of lines shown by default
                            />
                        </InputFieldContainer>

                        <InputFieldContainer>
                            <InputLabel>Check-in Instructions:</InputLabel>
                            <StyledTextArea
                                value={checkInInstructions}
                                onChange={handleCheckInInstructionsChange}
                                placeholder="Enter instructions here"
                                rows={recurrencePattern === 'Daily' ? 3 : 6}  // Adjust as needed for the number of lines shown by default
                            />
                        </InputFieldContainer>

                    </Card>



                </CardContainer>
            </TabContent>
        </Container>
    );
};

export default GroupAppoinmentFormPage;